import React from 'react'
import Block1 from '../../contents/checkout/block1/Block1'

function CheckOut() {
  return (
    <div>
      <Block1/>
    </div>
  )
}

export default CheckOut

import React from 'react'


const Block3 = () => {
  return (
    <div>
        {/* <Map
            apiKey={`${process.env.REACT_APP_GOOGLE_CLIENTID}`}
            // defaultZoom={8}
            // defaultCenter={{ lat: 37.7749, lng: -122.4194 }}
        /> */}
    </div>
  )
}

export default Block3
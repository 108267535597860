import React from 'react'
import Block1 from '../../../contents/subCategory/Block1'
import Block2 from '../../../contents/subCategory/Block2'

const SubCategory = () => {
  return (
    <>
      <Block1/>
      <Block2/>
    </>
  )
}

export default SubCategory

import { Typography } from '@mui/material'
import React from 'react'
import Block1 from '../../contents/account/Block1'

const Account = () => {
  return (
    <>
      <Block1/>
    </>
  )
}

export default Account

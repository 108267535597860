import React from 'react'
import Block1 from '../../../contents/category/Block1'
import Navbar from '../../../layout/mainLayout/navbar/Navbar'
import Block2 from '../../../contents/category/Block2'

const Category = () => {
  return (
    <>
    {/* <Navbar/> */}
     <Block1/>
     <Block2/>
    </>
  )
}

export default Category
